import React from "react"
import "./Description.scss"

import { useTranslation } from "react-i18next"
import facilities from "./images/Group 277.svg"
import activities from "./images/Group 278.svg"
import subscription from "./images/mobile_phone_number_1.png"

function Description({ platinum_amount, platinum_activities_amount }) {
  const { t } = useTranslation()

  const items = [
    {
      icon: facilities,
      title: t("description.item-1.title", { number: platinum_amount }),
      text: t("description.item-1.text"),
    },
    {
      icon: activities,
      title: t("description.item-2.title", {
        number: platinum_activities_amount,
      }),
      text: t("description.item-2.text"),
    },
    {
      icon: subscription,
      title: t("description.item-3.title"),
      text: t("description.item-3.text"),
    },
  ]

  return (
    <section className="description">
      {items.map((item) => {
        const { icon, title, text } = item
        return (
          <div key={title} style={{ backgroundImage: `url('${icon}')` }}>
            <h5>{title}</h5>
            <p>{text}</p>
          </div>
        )
      })}
    </section>
  )
}

export default Description
