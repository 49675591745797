import React from "react"
import "./HowItWorks.scss"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useMediaQuery } from "../../hooks/useMediaQuery.js"

const steps = [
  "howItWorks.steps.step-1",
  "howItWorks.steps.step-2",
  "howItWorks.steps.step-3",
  "howItWorks.steps.step-4",
]

function HowItWorks() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "blurred"}}, sort: {fields: name}) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          }
        }
      }
    }
  `)
  let isPageMedium = useMediaQuery("(min-width: 768px)")
  const { t } = useTranslation();
  return (
    <div className="how-it-works">
      <h3>{t("howItWorks.title")}</h3>

      <div className="steps">
        {steps.map((step, i) => {
          return (
            <div key={i} className="cardParent"> 
              <div className={`cardCover ${isPageMedium ? 'largeView': ''}`}>
                <GatsbyImage image={data.allFile.nodes[i].childImageSharp.gatsbyImageData} alt="card" />
              </div>
              <p>{t(step)}</p>
              <span>{i + 1}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HowItWorks
